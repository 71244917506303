import { theme, extendTheme } from '@chakra-ui/react';
import typography             from '../../../utils/typography';

const combinedTheme = extendTheme({
  fonts: {
    body: typography.options.bodyFontFamily.toString(),
    heading: typography.options.headerFontFamily.toString(),
  },
}, theme);

export default combinedTheme;