import Typography from 'typography';

const typography = new Typography({
  baseFontSize: '17px',
  baseLineHeight: 1.56,
  headerGray: 15,
  bodyGray: 18,
  scaleRatio: 2.0,
  headerWeight: '200',
  bodyWeight: '400',
  headerFontFamily: ['Source Sans Pro', 'Helvetica', 'sans-serif'],
  bodyFontFamily: ['Source Sans Pro', 'Helvetica', 'sans-serif'],
});

typography.overrideThemeStyles = () => ({
  a: {
    textDecoration: 'none',
    backgroundImage: 'unset',
    textShadow: 'unset',
  }
})

export default typography;
