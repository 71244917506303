import styled from '@emotion/styled';

export const Sidebar = styled.div`
  height: 100%;
  background-color: var(--chakra-colors-base-500);
  ul {
    list-style-type: none;
    margin: 0;
    background-color: var(--chakra-colors-base-500);
    li {
      margin: 0;
      padding: 0;
      .subnav {
        a {
          padding-left: 80px;
          padding-right: 20px;
        }
      }
      a {
        display: block;
        width: 100%;
        height: 100%;
        padding: 20px 20px 20px 40px;
        transition: background-color 0.2s;
        text-decoration: none;
        color: unset;
        &:hover, 
        &.current {
          color: ${props => props.theme.brand_color};
          background-color: var(--chakra-colors-base-600);
          font-weight: bold;
        }
      }
    }
  }
`;