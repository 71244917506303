import React                             from 'react';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';

import { useHeaderData } from '@interness/web-core/src/hooks';
import Link              from '@interness/web-core/src/components/elements/Link/Link';
import Mounted           from '@interness/web-core/src/components/structure/Mounted/Mounted';
import RolexClock        from '@interness/web-core/src/components/modules/RolexClock/RolexClock';

import PrimaryMenu from '../PrimaryMenu';

const Header = () => {
    const data = useHeaderData();
    const rolexData = data.config.brand_config[0];
    const displayMenu = useBreakpointValue(
      {
        base: 'none',
        md: 'none',
        lg: 'block',
        xl: 'block',
        '2xl': 'block',
      },
      {
        fallback: 'md',
      },
    )

    const flexJustifyContent = useBreakpointValue(
      {
        base: 'center',
        md: 'center',
        lg: 'space-between',
        xl: 'space-between',
        '2xl': 'space-between',
      },
      {
        fallback: 'md',
      },
    )


    return (
      <Mounted>
        <Flex h={'120px'} w={'100%'} px={['10px', '10px', '40px', '40px']} bgColor="base.500" boxShadow="md" alignItems="center"
              justifyContent={flexJustifyContent}>
          <Flex fontSize="2.5rem" fontWeight="light"
                alignItems="center">
            <Box>
              <Link to="/">
                <img style={{
                  maxWidth: '100%',
                  maxHeight: '80px',
                  minHeight: '60px',
                  margin: '0',
                }} src={data.logo.localFile.publicURL} alt={data.seo_title} title={data.seo_title}/>
              </Link>
            </Box>
          </Flex>
          <Box pl="30px" marginLeft='auto' display={displayMenu}><PrimaryMenu/></Box>
          {rolexData && rolexData.rolex_clock && <Box pl='15px'>
            <RolexClock/>
          </Box>}
        </Flex>
      </Mounted>
    )
  }
;

export default Header;
