import React                       from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import NewMenuStructure from '@interness/web-core/src/components/structure/MenuStructure/NewMenuStructure';
import MenuStructure    from '@interness/web-core/src/components/structure/MenuStructure/MenuStructure';
import { findT }        from '@interness/web-core/src/components/_helpers';

import * as S from './styles';

const PrimaryNav = () => {
  const data = useStaticQuery(query);
  const menu = data.directusMenus ? findT(data.directusMenus.translations, 'de').config : null;
  const menuNew = data.directusMenusNew ? data.directusMenusNew.config : null;
  return (
    <S.Sidebar>
      {menuNew
        ? <NewMenuStructure menu={menuNew}/>
        : <MenuStructure menu={menu}/>
      }
    </S.Sidebar>
  )
};

const query = graphql`
    query {
        directusMenus(position: {eq: "header"}) {
            ...Menus
        }
        directusMenusNew(position: {eq: "primary"}) {
            language
            position
            config {
                anchor
                display_name
                groups {
                    display_name
                    sub {
                        anchor
                        display_name
                        path
                    }
                }
                path
                external_path
                route
                sub {
                    display_name
                    path
                    path_prefix
                    external_path
                }
            }
        }
    }
`;

export default PrimaryNav;