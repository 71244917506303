import React                            from 'react';
import { graphql, useStaticQuery }      from 'gatsby';
import { VStack, Heading, Box, Button } from '@chakra-ui/react';
import { find }                         from 'lodash';

import PhoneNumber from '@interness/web-core/src/components/text/PhoneNumber/PhoneNumber';
import Link        from '@interness/web-core/src/components/elements/Link/Link';
import Mounted     from '@interness/web-core/src/components/structure/Mounted/Mounted';

import OpeningPeriods from './OpeningPeriods';

const ContactSidebarContent = () => {
  const data = useStaticQuery(query);
  const { emails, numbers, addresses, display_name } = data.directusCompany;
  const email = find(emails, email => email.primary).email;
  const phoneNumber = find(numbers, number => number.type === 'phone' && number.primary);
  const address = encodeURIComponent(`${addresses[0].street}+${addresses[0].number}+${addresses[0].zip_code}+${addresses[0].city}`);
  return (
    <VStack>
      <Box w="90%">
        <Heading as="h4" size="md" mb={4} mt={6}>{display_name}</Heading>
        <Box w="100%" p={4}>
          <Heading as="h5" size="sm" mb={4}>Kontakt</Heading>
          <address>
            <p>{addresses[0].street} {addresses[0].number} <br/>
              {addresses[0].zip_code} {addresses[0].city}</p>
          </address>
          <p><Link to={`https://www.google.com/maps/dir/?api=1&destination=${address}`} external>Mit Google Maps
            navigieren</Link></p>
          <p><Link to={`mailto:${email}`} external>{email}</Link> <br/>
            <PhoneNumber number={phoneNumber.number}/></p>
        </Box>
        {data.site.siteMetadata.hasCalCom &&
          <Box w="100%" p={4}><Button colorScheme="brand" sx={{
            textDecoration: 'none'
          }} as={Link} to="/terminvereinbarung" w='100%'>Zur Terminvereinbarung</Button></Box>}
        <Box w="100%" p={4} borderBottom="1px solid #e8e8e8">
          <Mounted>
            <OpeningPeriods openingPeriods={data.allDirectusOpeningPeriods.nodes}/>
          </Mounted>
        </Box>
      </Box>
      {data.allDirectusBranches.nodes.map(branch => {
        const branchAddress = encodeURIComponent(`${branch.street}+${branch.zip}+${branch.city}`);
        return (
          <Box w="90%">
            <Heading as="h4" size="md" mb={4} mt={6}>{branch.display_name}</Heading>
            <Box w="100%" p={4}>
              <Heading as="h5" size="sm" mb={4}>Kontakt</Heading>
              <address>
                <p>{branch.street}<br/>
                  {branch.zip} {branch.city}</p>
              </address>
              <p><Link to={`https://www.google.com/maps/dir/?api=1&destination=${branchAddress}`} external>Mit Google
                Maps
                Navigieren</Link></p>
              <p><Link to={`mailto:${email}`} external>{email}</Link> <br/>
                <PhoneNumber number={branch.phone}/></p>
            </Box>
            <Box w="100%" p={4} borderBottom="1px solid #e8e8e8">
              <OpeningPeriods openingPeriods={branch.opening_periods}/>
            </Box>
          </Box>
        );
      })}
    </VStack>
  )
};

const query = graphql`
    query {
        site {
            siteMetadata {
                hasCalCom
            }
        }
        directusCompany(id: {ne: "dummy"}) {
            emails {
                email
                primary
            }
            numbers {
                number
                primary
                type
            }
            company_name
            display_name
            addresses {
                street
                number
                zip_code
                city
            }
        }
        allDirectusOpeningPeriods(filter: {id: {ne: "dummy"}}) {
            nodes {
                open
                sort
                time_open
                time_close
                day
                free_text
            }
        }
        allDirectusBranches(filter: {id: {ne: "dummy"}}) {
            nodes {
                display_name
                email
                city
                phone
                street
                zip
                opening_periods {
                    day
                    free_text
                    open
                    sort
                    time_close
                    time_open
                }
            }
        }
    }
`;

export default ContactSidebarContent;