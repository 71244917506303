import { useStaticQuery, graphql } from 'gatsby';

export const useEcom = () => {
  const data = useStaticQuery(query);
    let hasEcom = data.allSitePlugin.nodes.filter(plugin => plugin.name === '@interness/ecommerce-addon');
    hasEcom = hasEcom.length === 1;

    return {
        hasEcom,
    }
}

const query = graphql`
    query {
        allSitePlugin(filter: {name: {regex: "/@interness/"}}) {
            nodes {
                version
                name
            }
        }
    }
`