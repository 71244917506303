import React, { useContext }                             from 'react';
import { graphql, useStaticQuery }                       from 'gatsby';
import { HStack, IconButton, VStack, Divider, useTheme } from '@chakra-ui/react';
import { HamburgerIcon }                                 from '@chakra-ui/icons';
import { MdOutlineContactPhone }                         from 'react-icons/md';
import { FaFacebook, FaInstagram }                       from 'react-icons/fa';

import { GlobalContext } from '@interness/web-core/src/providers/GlobalContext';
import { useEcom }       from '@interness/web-core/src/hooks/useEcom';
import CartIcon          from '@interness/ecommerce-addon/src/components/CartIcon/CartIcon';

const PrimaryMenu = () => {
  const data = useStaticQuery(query);
  const theme = useTheme();
  const { hasEcom } = useEcom();
  const { onSidebarOpen, setSidebarContent } = useContext(GlobalContext);
  const onMenuItemClick = (content) => {
    setSidebarContent(content);
    onSidebarOpen();
  }

  const colorModeButtonStyles = {
    colorScheme: theme.initialColorMode === 'light' ? 'gray' : 'base',
    variant: theme.initialColorMode === 'light' ? 'ghost' : 'solid'
  }

  const socialIcons = {
    instagram: <FaInstagram/>,
    facebook: <FaFacebook/>,
  }

  const hasPrimaryMenu = data.allDirectusMenusNew.nodes.filter(e => e.position === 'primary').length > 0;
  const hasSocialMenu = data.allDirectusMenusNew.nodes.filter(e => e.position === 'social').length > 0;

  return (
    <>
      <HStack justify="center" h="100%">
        <HStack spacing={4} mr={4} justify="center" sx={{
          p: {
            fontSize: '0.6rem'
          }
        }}>
          {hasPrimaryMenu && <VStack spacing={0}>
            <IconButton size="lg"
                        {...colorModeButtonStyles}
                        onClick={() => onMenuItemClick('primaryMenu')} aria-label="Menu"
                        icon={<HamburgerIcon/>}/>
            <p>Menü</p>
          </VStack>}
          <VStack spacing={0}>
            <IconButton size="lg"
                        {...colorModeButtonStyles}
                        onClick={() => onMenuItemClick('contact')} aria-label="Kontakt"
                        icon={<MdOutlineContactPhone/>}/>
            <p>Kontakt</p>
          </VStack>
          {hasEcom && <CartIcon buttonStyle={colorModeButtonStyles}/>}
        </HStack>
        {hasSocialMenu &&
          <>
            <Divider orientation="vertical" sx={{
              borderColor: theme.initialColorMode === 'light' ? '#333' : '#fff',
              height: '50px'
            }}/>
            <HStack spacing={2} justify="center" sx={{
              p: {
                fontSize: '0.6rem'
              }
            }}>
              {data.socialMediaMenu.config.map(social => (
                <VStack spacing={0} key={social.external_path}>
                  <IconButton
                    as={'a'}
                    href={social.external_path}
                    target="_blank" rel="noopener noreferrer"
                    size="md"
                    {...colorModeButtonStyles}
                    aria-label={social.display_name}
                    icon={socialIcons[social.media]}/>
                </VStack>
              ))}
            </HStack>
          </>
        }
      </HStack>
    </>
  )
};

const query = graphql`
    query {
        allDirectusMenusNew(filter: {position: {ne: "dummy"}}) {
            nodes {
                position
            }
        }
        socialMediaMenu: directusMenusNew(position: {eq: "social"}) {
            config {
                display_name
                external_path
                media
            }
        }
    }
`;

export default PrimaryMenu;
