import React, { useContext, useEffect, useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton, DrawerFooter, IconButton,
  useTheme,
}                                                 from '@chakra-ui/react'
import { CloseIcon }                              from '@chakra-ui/icons';
import { readableColor }                          from 'polished';

import { GlobalContext } from '@interness/web-core/src/providers/GlobalContext';

import PrimaryNav            from './PrimaryNav/PrimaryNav';
import ContactSidebarContent from './ContactSidebarContent';


const Sidebar = ({ footerDisplay }) => {
  const [mounted, setMounted] = useState(false);
  const { isSidebarOpen, onSidebarClose, sidebarContent } = useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState(isSidebarOpen);
  const theme = useTheme();

  useEffect(() => {
    setMounted(true)
    setIsOpen(isSidebarOpen);
  }, [isSidebarOpen])

  const onClose = () => {
    onSidebarClose()
    setIsOpen(false);
  }

  if (!mounted) {
    return null;
  }

  return (
    <Drawer
      isOpen={isOpen}
      placement={'right'}
      onClose={onClose}
      size={'sm'}
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerCloseButton sx={{
          color: readableColor(theme.colors.base['500'])
        }}/>
        <DrawerHeader bg="base.500">
          {sidebarContent === 'primaryMenu' && <>Menü</>}
          {sidebarContent === 'contact' && <>Kontakt</>}
        </DrawerHeader>
        <DrawerBody p={0} bgColor="base.500">
          {sidebarContent === 'primaryMenu' && <PrimaryNav/>}
          {sidebarContent === 'contact' && <ContactSidebarContent/>}
        </DrawerBody>
        <DrawerFooter textAlign="right" borderTopWidth="1px" bg="base.500" display={footerDisplay}>
          <IconButton colorScheme="base" onClick={onClose} aria-label="Close" icon={<CloseIcon/>}/>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  )
};

export default Sidebar;